body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  /* background-color: #282c34; */
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.plottable-colors-0 {
    background-color: #5279c7; /* INDIGO */
  }
  
  .plottable-colors-1 {
    background-color: #fd373e; /* CORAL_RED */
  }
  
  .plottable-colors-2 {
    background-color: #63c261; /* FERN */
  }
  
  .plottable-colors-3 {
    background-color: #fad419; /* BRIGHT_SUN */
  }
  
  .plottable-colors-4 {
    background-color: #2c2b6f; /* JACARTA */
  }
  
  .plottable-colors-5 {
    background-color: #ff7939; /* BURNING_ORANGE */
  }
  
  .plottable-colors-6 {
    background-color: #db2e65; /* CERISE_RED */
  }
  
  .plottable-colors-7 {
    background-color: #99ce50; /* CONIFER */
  }
  
  .plottable-colors-8 {
    background-color: #962565; /* ROYAL_HEATH */
  }
  
  .plottable-colors-9 {
    background-color: #06cccc; /* ROBINS_EGG_BLUE */
  }
  
  /**
   * User-supplied renderTo element.
   */
  .plottable {
    display: block; /* must be block elements for width/height calculations to work in Firefox. */
    pointer-events: visibleFill;
    position: relative;
    /**
     * Pre 3.0, users could set the dimension of the root element in two ways: either using CSS
     * (inline or through a stylesheet), or using the SVG width/height attributes. By default, we
     * set the SVG width/height attributes to 100%.
     *
     * Post 3.0 the root element is always a normal div and the only way to set the dimensions is
     * to use CSS. To replicate the "100%-by-default" behavior, we apply width/height 100%.
     */
    width: 100%;
    height: 100%;
  }
  
  /**
   * The _element that roots each Component's DOM.
   */
  .plottable .component {
    /* Allow components to be positioned with explicit left/top/width/height styles */
    position: absolute;
  }
  
  .plottable .background-container,
  .plottable .content,
  .plottable .foreground-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /**
   * Don't allow svg elements above the content to steal events
   */
  .plottable .foreground-container {
    pointer-events: none;
  }
  
  .plottable .component-overflow-hidden {
    overflow: hidden;
  }
  
  .plottable .component-overflow-visible {
    overflow: visible;
  }
  
  .plottable .plot-canvas-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .plottable .plot-canvas {
    width: 100%;
    height: 100%;
    /**
     * Play well with deferred rendering.
     */
    -webkit-transform-origin: 0px 0px 0px;
            transform-origin: 0px 0px 0px;
  }
  
  .plottable text {
    text-rendering: geometricPrecision;
  }
  
  .plottable .label text {
    fill: #32313F;
  }
  
  .plottable .bar-label-text-area text,
  .plottable .scatter-label-text-area text {
    font-size: 12px;
  }
  
  .plottable .label-area text {
    fill: #32313F;
    font-size: 14px;
  }
  
  .plottable .light-label text {
    fill: white;
  }
  
  .plottable .dark-label text {
    fill: #32313F;
  }
  
  .plottable .off-bar-label text {
    fill: #32313F;
  }
  
  .plottable .stacked-bar-label text {
    fill: #32313F;
    font-style: normal;
  }
  
  .plottable .stacked-bar-plot .off-bar-label {
    /* HACKHACK #2795: correct off-bar label logic to be implemented on StackedBar */
    visibility: hidden !important;
  }
  
  .plottable .axis-label text {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .plottable .title-label text {
    font-size: 20px;
    font-weight: bold;
  }
  
  .plottable .axis line.baseline {
    stroke: #CCC;
    stroke-width: 1px;
  }
  
  .plottable .axis line.tick-mark {
    stroke: #CCC;
    stroke-width: 1px;
  }
  
  .plottable .axis text {
    fill: #32313F;
    font-size: 12px;
    font-weight: 200;
    line-height: normal;
  }
  
  .plottable .axis .annotation-circle {
    fill: white;
    stroke-width: 1px;
    stroke: #CCC;
  }
  
  .plottable .axis .annotation-line {
    stroke: #CCC;
    stroke-width: 1px;
  }
  
  .plottable .axis .annotation-rect {
    stroke: #CCC;
    stroke-width: 1px;
    fill: white;
  }
  
  .plottable .bar-plot .baseline {
    stroke: #999;
  }
  
  .plottable .gridlines line {
    stroke: #3C3C3C; /* hackhack: gridlines should be solid; see #820 */
    opacity: 0.25;
    stroke-width: 1px;
  }
  
  .plottable .selection-box-layer .selection-area {
    fill: black;
    fill-opacity: 0.03;
    stroke: #CCC;
  }
  /* DragBoxLayer */
  .plottable .drag-box-layer.x-resizable .drag-edge-lr {
    cursor: ew-resize;
  }
  .plottable .drag-box-layer.y-resizable .drag-edge-tb {
    cursor: ns-resize;
  }
  
  .plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-tl {
    cursor: nwse-resize;
  }
  .plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-tr {
    cursor: nesw-resize;
  }
  .plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-bl {
    cursor: nesw-resize;
  }
  .plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-br {
    cursor: nwse-resize;
  }
  
  .plottable .drag-box-layer.movable .selection-area {
    cursor: move; /* IE fallback */
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .plottable .drag-box-layer.movable .selection-area:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  /* /DragBoxLayer */
  
  .plottable .guide-line-layer line.guide-line {
    stroke: #CCC;
    stroke-width: 1px;
  }
  
  .plottable .drag-line-layer.enabled.vertical line.drag-edge {
    cursor: ew-resize;
  }
  
  .plottable .drag-line-layer.enabled.horizontal line.drag-edge {
    cursor: ns-resize;
  }
  
  .plottable .legend text {
    fill: #32313F;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
  }
  
  .plottable .interpolated-color-legend rect.swatch-bounding-box {
    fill: none;
    stroke: #CCC;
    stroke-width: 1px;
    pointer-events: none;
  }
  
  .plottable .waterfall-plot line.connector {
    stroke: #CCC;
    stroke-width: 1px;
  }
  
  .plottable .pie-plot .arc.outline {
    stroke-linejoin: round;
  }
.submission-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Helvetica Neue", sans-serif;   
    font-weight: 400;
    font-size: 1.5em;
    padding-top: 3%;
}

.submission-form input {
    font-size: 24px;
    width: 100%;
    height: 40px;
}

.link-to-imdb {
    margin-top: 1%;
}
