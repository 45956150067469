.submission-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Helvetica Neue", sans-serif;   
    font-weight: 400;
    font-size: 1.5em;
    padding-top: 3%;
}

.submission-form input {
    font-size: 24px;
    width: 100%;
    height: 40px;
}

.link-to-imdb {
    margin-top: 1%;
}